
import { defineComponent } from 'vue'
import { debounce } from 'lodash'
import { useOrganizationAreas } from '@dview/logic'
import { useAppInsights, selectedFiltersToString } from '@dview/shared/util'
import { trashOutline } from 'ionicons/icons'

export default defineComponent({
    setup() {
        const { activeAreasFilter, areaFilterModel, clearAreas, save: saveActiveAreas } = useOrganizationAreas()
        const { trackEvent } = useAppInsights()

        // Listening to "ion-change" event will cause a lot of change events to occur in other
        // dropdowns when an item is selected in a given dropdown.
        // The events trigger rapidly after each other, so in order not to call backend too
        // much and unnecessarily, the "saveActiveFilter" method is curried by a debounce function.
        const autoSaveFilter = debounce(() => {
            saveActiveAreas()

            trackEvent({
                name: 'Organization filters set in Settings Page',
                properties: {
                    'org-filters': selectedFiltersToString(areaFilterModel)
                }
            })

        }, 100)

        return {
            areaFilterModel,
            clearAreas,
            autoSaveFilter,
            trashOutline,
        }
    },
})
